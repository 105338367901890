.header-div {
  width: 100%;
}

.header-div.width80 {
  width: 80%;
  margin: 0 auto;
}

.header-div .text-dim {
  color: #f5f7fa;
}

.header-div h3 {
  color: #f5f7fa !important;
}

.parent_login {
}

.header-logo {
  width: 10rem;
  margin-top: 9px;
  position: absolute;
  left: -15px;
  top: -1.3rem;
}

.header-drop {
  width: 90%;
}

.header-drop .ant-select-selector {
  border-radius: 1.5rem !important;
}

.header-drop .ant-select-selection-item {
  color: var(--text-primary) !important;
}

.logoContainer {
  margin-top: 0.3em;
}

.head-budget {
  font-size: 13px;
  margin-top: 0.8em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.head-budget > * {
  color: #262626;
}

.head-budget h3 {
  font-size: 18px !important;
  color: #262626 !important;
}

.head-budget svg {
  fill: #262626;
}

.psd_content .ant-form-item-label label {
  color: var(--text-primary) !important;
}

.header-title {
  color: #262626;
  font-weight: 700;
  font-size: 2rem;
}

.first-letter {
  color: #122549;
  font-weight: 700;
  font-size: 1.2rem;
}

.header-div .circle {
  background: #6ae3c730;
  border-radius: 50%;
  border: 4px solid #fff;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-name {
  color: #122549;
  font-weight: 600;
}

.mrHalf {
  margin-right: 0.5rem;
}

.align-items-center {
  align-items: center;
}

.justify-content-end {
  justify-content: end;
}

.down {
  width: 1.3rem;
}

.rtl .user-drop .circle {
  margin-right: 0px;
  margin-left: 10px;
}

.user-drop .circle {
  margin-right: 10px;
}

.user-drop {
  background-color: #fff;
  border-radius: 25px;
  height: 3rem;
  padding-right: 10px;
}

.circle .ant-badge {
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.parent_login {
  cursor: pointer;
  text-align: end;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0.6rem 2rem;
  border-radius: 25px;
  height: 3rem;
}

.parent_login .branch_name {
  white-space: nowrap;
  max-width: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #454545;
  margin-bottom: 0;
  margin-right: 5px;
}

.branch_name h4 {
  color: #454545;
}

.parent_login .arrow_icon {
  position: relative;
  right: -0.5rem;
}

.branch_list .branch_list_select {
  padding: 1.5rem 0rem;
  cursor: pointer;
}

.branch_list .branch_list_div {
  cursor: pointer;
}

.branch_list .branch_list_div:hover {
  background: rgba(225, 225, 225, 0.3);
}

.branch_active_bg {
  background: rgba(225, 225, 225, 0.3);
}

.branch_list .branch_logo {
  margin-right: 0.5rem;
  width: 3rem;
  height: 3rem;
  border-radius: 5px;
  background: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.branch_list .branch_logo img {
  width: 60%;
  height: 60%;
}

.branch_list .check_mark {
  width: 1.3rem;
  height: 1.3rem;
  display: flex;
}

.branch_list .check_mark img {
  width: 100%;
  height: 100%;
}

.branch_list .organisation_create,
.branch_list .organisation_id {
  color: #767676;
  font-size: 0.9rem;
  font-weight: 400;
}

.organisation_modal .ant-modal-body {
  padding: 24px 0px;
}

.organisation_modal .branch_list_div {
  padding: 0px 24px;
}

.ant-badge {
  position: relative;
}

.cart .ant-badge-count {
  position: absolute;
  top: -9px !important;
}

.ant-badge.cart {
  background: transparent;
  border: none;
}

.switch-portal h4 {
  font-size: 1rem;
  font-weight: 500;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  border: 4px solid #e4e9f2;
  transition: background-color 0.3s ease;
  width: 3rem;
  height: 3rem;
}

.switch-portal h4 span svg {
  width: 2rem;
  height: 1.4rem;
}

.tableHeader-select .ant-select-selector {
  width: 16rem !important;
  height: 45px !important;
  background-color: rgb(250, 250, 250) !important;
  border: 1px solid rgb(234, 234, 234) !important;
  border-radius: 5px !important;
}

.modal-body {
  padding: 1rem;
}

.modal-body h4 {
  color: #00000099;
  opacity: 0.5;
}

.modal-body .account-list {
  background: #fafbfd 0% 0% no-repeat padding-box;
  border: 1px solid #edf0f5;
  border-radius: 9px;
}

.modal-body .account-list li {
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  border-bottom: #edf0f5 1px solid;
}

.modal-body .account-list li:last-child {
  border-bottom: none;
}
.modal-body .account-list img {
  border-radius: 50%;
}
.modal-body .account-list .account-name,
.modal-body .account-list .account-info {
  font-size: 1rem;
  font-weight: 500;
  color: #363636;
}

.account-role {
  background: #f3edff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  color: #170a33;
  font-size: 0.7rem;
  font-weight: 500;
  padding: 0.2rem 0.5rem;
  margin-left: 0.5rem;
}

.account-actions .action-link {
  color: #363636;
  display: block;
  font-weight: 500;
  padding: 0.4rem 1rem;
  cursor: pointer;
}
