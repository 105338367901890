.request-money .ant-steps-item-container {
  display: flex;
  align-items: center;
}

.request-money .ant-steps {
  width: 50% !important;
}

.request-money .ant-steps-item-title {
  font-weight: 600 !important;
}

.request-money h2 {
  color: #212121;
  font-weight: 1.4rem;
}
.request-money p {
  margin: 0;
}
.request-money .sending-inv {
  background: #fdffff 0% 0% no-repeat padding-box;
  border: 0.75px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 1rem;
}
.request-money .sending-inv p {
  margin-left: 1rem;
  font-weight: 500;
  font-size: 1.1rem;
}

.contact-details h4 {
  color: #212121;
  font-size: 1.2rem;
  margin-top: 3rem;
}

.contact-details p {
  color: #848485;
}

.request-money .form-body {
  margin-top: 2rem;
}
.edit-link {
  color: #4081f4;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  cursor: pointer;
}

.link {
  color: #4081f4;
  font-weight: 500;
  font-size: 14px;
}

.ant-input-affix-wrapper.pp-form-input {
  padding: 0;
}
.text-right {
  text-align: right;
}
.helper-text {
  color: #848485;
  font-size: 0.9rem;
  margin-top: 6px !important;
}

.expires {
  background: #e0ecfe 0% 0% no-repeat padding-box;
  border-radius: 6px;
  color: #212121;
  font-weight: 600;
  padding: 1rem;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  gap: 5px;
}
.p-method {
  display: flex;
  align-items: center;
  gap: 5px;
}
.p-method .helper-text {
  margin-top: 0 !important;
}
.p-method .title {
  color: #170a33;
  font-size: 1rem;
}

.p-method .link {
  color: #4081f4;
  margin-left: 4px;
}
.notes textarea {
  border: none !important;
  border-radius: 0 !important;
}
.notes .ant-input {
  border: none;
  border-bottom: 1px solid #d9d9d9 !important;
  border-radius: 0 !important;
}
.notes .ant-input:focus {
  box-shadow: none;
}

.attachments label {
  font-size: 14px;
  color: rgb(59, 59, 59);
  display: inline-block;
  padding-bottom: 5px;
}

.attachments .ant-upload-drag-container {
  display: flex !important;
  align-items: center;
  padding: 1rem !important;
}

.attachments .ant-upload-drag {
  background: #fafbfd !important;
  border: 1px dashed #cecece !important;
  border-radius: 10px !important;
}
.texts strong {
  text-align: left;
}
.texts {
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-left: 2rem;
}
.req_mon .request-money {
  margin-top: 5rem;
}
.req_mon .ant-modal-header {
  position: fixed;
  top: 0;
  z-index: 9;
  width: 100%;
}

.req_mon .ant-modal-close {
  position: fixed;
}

.amt-details p {
  font-weight: 600;
  font-size: 1.2rem;
}

.amt-details .helper-text {
  font-weight: 500 !important;
  margin-left: 0.5rem;
}

.amt-details h3 {
  font-size: 2.3rem;
}

.due-on {
  background: #f4f4f4;
  color: #2e2e2e;
  font-weight: 600;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 4px;
}
