@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Figtree", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
p {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  --disabled-state: #c5c1cc;
  --nav-bg: #190935;
  --primary-color: #00e6c5;
  --secondary-color: #c6fff7;
  --nav-item-hover: #12415a;
  --disabled-text-color: #a39dae;
  --button-hover: #5effe8;
  --form-label-color: #212121;
  --button-text-color: #170a33;
  --input-box-shadow: #00e6c43a;
}

.capitalize {
  text-transform: capitalize;
}

ul {
  list-style: none;
}

.ant-badge-count {
  top: -10px !important;
  right: 0px !important;
}

.border-bottom-line {
  height: 0.1rem;
  background-color: #d9d9d9;
}

/* width css */

.w5 {
  width: 5%;
}

.w10 {
  width: 10%;
}

.w15 {
  width: 15%;
}

.w20 {
  width: 20%;
}

.w25 {
  width: 25% !important;
}

.w30 {
  width: 30%;
}

.w35 {
  width: 35%;
}

.w40 {
  width: 40%;
}

.w45 {
  width: 45%;
}

.w50 {
  width: 50% !important;
}

.w55 {
  width: 55%;
}

.w60 {
  width: 60%;
}

.w65 {
  width: 65%;
}

.w70 {
  width: 70%;
}

.w75 {
  width: 75%;
}

.w80 {
  width: 80%;
}

.w85 {
  width: 85%;
}

.w90 {
  width: 90%;
}

.w95 {
  width: 95%;
}

.w100 {
  width: 100% !important;
}

.h100 {
  height: 100% !important;
}

.h100vh {
  min-height: 100vh;
}

/* margin css */

.mlHalf {
  margin-left: 0.5rem;
}

.ml1 {
  margin-left: 1rem;
}

.ml1 {
  margin-left: 1.5rem;
}

.ml2 {
  margin-left: 2rem;
}

.ml3 {
  margin-left: 3rem;
}

.ml4 {
  margin-left: 4rem;
}

.ml5 {
  margin-left: 5rem;
}

.mb0 {
  margin-bottom: 0rem !important;
}

.mbHalf {
  margin-bottom: 0.5rem;
}

.mb0 {
  margin-bottom: 0rem;
}

.mb1 {
  margin-bottom: 1rem !important;
}

.mb2 {
  margin-bottom: 2rem;
}

.mb3 {
  margin-bottom: 3rem !important;
}

.mb4 {
  margin-bottom: 4rem;
}

.mb5 {
  margin-bottom: 5rem;
}

.mt0 {
  margin-top: 0rem !important;
}

.mtHalf {
  margin-top: 0.5rem;
}

.mt1 {
  margin-top: 1rem !important;
}

.mt2 {
  margin-top: 2rem;
}

.mt3 {
  margin-top: 3rem !important;
}

.mt4 {
  margin-top: 4rem;
}

.mt5 {
  margin-top: 5rem;
}

.mr1 {
  margin-right: 1rem !important;
}

.mr2 {
  margin-right: 2rem;
}

.mr3 {
  margin-right: 3rem;
}

.mr4 {
  margin-right: 4rem;
}

.mr5 {
  margin-right: 5rem;
}

.mAuto {
  margin: auto;
}

.m1 {
  margin: 1rem;
}

.m2 {
  margin: 2rem;
}

.m3 {
  margin: 3rem;
}

.m4 {
  margin: 4rem;
}

.m5 {
  margin: 5rem;
}

/* padding css */

.p0 {
  padding: 0rem !important;
}

.pHalf {
  padding: 0.5rem;
}

.p1 {
  padding: 1rem;
}

.p2 {
  padding: 2rem;
}

.p3 {
  padding: 3rem;
}

.p4 {
  padding: 4rem;
}

.p5 {
  padding: 5rem;
}

.pbHalf {
  padding-bottom: 0.5rem;
}

.pb0 {
  padding-bottom: 0rem !important;
}

.pb1 {
  padding-bottom: 1rem;
}

.pb2 {
  padding-bottom: 2rem;
}

.pb3 {
  padding-bottom: 3rem;
}

.pb4 {
  padding-bottom: 4rem;
}

.pb5 {
  padding-bottom: 5rem;
}

.pt0 {
  padding-top: 0px !important;
}

.pt1 {
  padding-top: 1rem;
}

.pt1Half {
  padding-top: 0.5rem;
}

.ptHalf {
  padding-top: 1.3rem;
}

.pt2 {
  padding-top: 2rem;
}

.pt3 {
  padding-top: 3rem;
}

.pt4 {
  padding-top: 4rem;
}

.pt5 {
  padding-top: 5rem;
}

.pr0 {
  padding-right: 0rem !important;
}

.pr1 {
  padding-right: 1rem;
}

.pr2 {
  padding-right: 2rem;
}

.pr3 {
  padding-right: 3rem;
}

.pr4 {
  padding-right: 4rem;
}

.pr5 {
  padding-right: 5rem;
}

.pl0 {
  padding-left: 0rem !important;
}

.pl1 {
  padding-left: 1rem;
}

.pl2 {
  padding-left: 2rem;
}

.pl3 {
  padding-left: 3rem;
}

.pl4 {
  padding-left: 4rem;
}

.pl5 {
  padding-left: 5rem;
}

/* display */

.d-flex {
  display: flex !important;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid;
}

/* flex */

.flex-col {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.align-center {
  align-items: center;
}

.flex-center,
.d-flex-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-center {
  display: flex !important;
  align-items: center !important;
}

.t-center {
  text-align: center;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.gap1 {
  gap: 1rem;
}

.gap2 {
  gap: 2rem;
}

.back-btn,
.send-btn {
  padding: 7px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  height: 41px;
  font-size: 0.95rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.back-btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--primary-color);
  color: var(--button-text-color) !important;
}

.send-btn {
  background: var(--primary-color) 0% 0% no-repeat padding-box;
  border: 1px solid var(--primary-color);
  color: #fff;
  color: var(--button-text-color);
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.send-btn:hover {
  background: var(--button-hover);
  border: 1px solid var(--button-hover);
}

.send-btn > span {
  color: var(--button-text-color);
}
.send-btn svg {
  fill: var(--button-text-color);
}

.back-btn:hover {
  background: var(--secondary-color);
  border: 1px solid var(--secondary-color);
}
.ant-input:hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: var(--primary-color) !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input:focus,
.ant-input-focused {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 2px var(--input-box-shadow) !important;
}
.ant-picker-focused {
  box-shadow: 0 0 0 2px var(--input-box-shadow) !important;
}
.select-statement .ant-tag:hover,
.select-statement .ant-tag.active {
  background: var(--secondary-color) !important;
  border: 1px solid var(--primary-color) !important;
}

.ant-card-body {
  padding: 1.4rem !important;
}

.ant-steps-item-container {
  display: flex;
  align-items: center;
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .send-btn,
  .back-btn {
    font-size: 1.2rem;
    height: 32px;
    border-radius: 4px;
  }

  .right_content p.sub-text {
    font-size: 1.4rem;
    line-height: 2rem;
  }
  .login-form input {
    height: 35px;
    font-size: 1.3rem;
  }

  .send-payment .item img.acc-img {
    width: 3.5rem;
  }

  .send-payment .item {
    margin-bottom: 0.3rem !important;
  }
  .send-payment .item.first {
    margin-bottom: 1rem !important;
  }

  .send-payment .label {
    font-size: 1rem !important;
    margin-bottom: 0 !important;
  }

  .select-statement .w60 {
    width: 80% !important;
  }

  .pp-input {
    width: 150px !important;
    height: 35px !important;
  }

  .pp-input input {
    height: 32px !important;
  }
  .bank-show-section .right-side button {
    height: 35px !important;
    font-size: 1rem !important;
    padding: 2px 10px !important;
  }
  .sec-btn svg {
    transform: scale(1.1) !important;
  }

  .action-btns button {
    height: 32px;
  }
  .top-filter .flt-btns button,
  .more,
  .pp-dropdownbutton button:nth-child(1),
  .pp-dropdownbutton {
    height: 34px !important;
  }
  .pp-dropdownbutton button:nth-child(1),
  .more {
    border-radius: 4px !important;
  }
  .pp-dropdownbutton button:nth-child(2) {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }

  .table.tab {
    font-size: 1rem;
  }
  .send-payment .w40 {
    width: 60% !important;
  }

  .sub-menu-children-item .nav-label {
    font-size: 0.95rem;
  }
}
