.approve {
  background: #48b553 0% 0% no-repeat padding-box;
}

.reject {
  background: #efa42c 0% 0% no-repeat padding-box;
}

.delete {
  background: #ff4d4f 0% 0% no-repeat padding-box;
}

.add-btn {
  background: #1890ff 0% 0% no-repeat padding-box;
}
.add-btn.bgWhite {
  background: #ffffff !important;
}

.action-btns button {
  color: white;
  font-weight: 600;
  /* border-radius: 7px; */
  margin-right: 1rem;
}

.action-btns .more {
  background: var(--bg-light) 0% 0% no-repeat padding-box;
  border: 1px solid #d6d6d6;
  color: #4b5660;
}

.action-btns {
  align-items: center;
}

.head h3 {
  color: #4b5660;
  font-size: 1.5rem;
  margin-right: 1rem;
  margin-bottom: 0;
}

.head {
  padding: 2rem;
}

.duration {
  color: #2b2b2b;
  font-weight: 500;
  display: block;
  margin-top: 0.5rem;
}

hr {
  border-bottom: 1px solid var(--text-db);
  border-top: 0;
}

.title-card {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px solid #edf0f5;
  border-radius: 9px;
}

.expense-info .title-card {
  background: #fff;
}

.title-card-user {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px solid #edf0f5;
  border-radius: 9px;
  width: 100%;
}

.advance-info .title-card:first-child {
  flex: 0.6;
}
.advance-info .title-card:last-child {
  flex: 0.4;
}

.advance-info {
  display: flex;
  margin: 2rem;
  gap: 2rem;
}

.advance-info .title-card p span {
  color: #1c2b36;
  font-weight: 600;
  display: block;
}

.advance-info .title-card p span.dim {
  opacity: 0.4;
  font-size: 0.9rem;
}

.advance-info .title-card-user p span {
  color: #1c2b36;
  font-weight: 600;
  display: block;
}

.advance-info .title-card-user p span.dim {
  opacity: 0.4;
  font-size: 0.9rem;
}

.advance-info .sm {
  font-size: 0.9rem;
}

.advance-info .title-card p {
  flex: 0.33;
  margin: 1rem;
  border-right: 1px solid #edf0f5;
}

.advance-info .title-card p:last-child {
  border-right: none;
}

.advance-total {
  padding: 1rem 2rem;
}

.advance-total-user {
  padding: 1rem 2rem;
}

.advance-total p label {
  color: #58666e;
  font-weight: 600;
  font-size: 0.8rem;
}

.advance-total-user p label {
  color: #58666e;
  font-weight: 600;
  font-size: 0.8rem;
}

.advance-total p span.lg {
  font-size: 1.3rem;
}

.advance-total-user p span.lg {
  font-size: 1.3rem;
}

.advance-total {
  display: flex;
  justify-content: space-around;
}

.advance-total-user {
  display: flex;
}

.advance-total p span.link {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 0.8rem;
}

.advance-total.title-card p {
  flex: 0.5;
}

.expense-info .title-card {
  padding: 15px;
}

.expense-info {
  grid-gap: 2rem;
  gap: 2rem;
}

.expense-info h3,
.expense-info span,
.expense-info p {
  color: #58666e;
}

.expense-info h2 {
  color: rgba(0, 0, 0, 0.85) !important;
}

.expense-info .boldHead p {
  color: #333;
  font-weight: 500;
}

.contentAreas .ant-collapse {
  background-color: #fff;
  border: none;
  margin-top: 20px;
}

.contentAreas .ant-collapse .ant-steps-item-tail::after {
  background-color: #1890ff;
  margin-top: 1px;
}

.contentAreas .ant-collapse .ant-collapse-item {
  border-bottom: none;
}

.business-info {
  margin: 2rem;
  grid-gap: 2rem;
  gap: 2rem;
}

.business-info .title-card p {
  margin: 1rem 3rem;
  border-right: 1px solid #edf0f5;
  display: inline-block;
  padding-right: 3rem;
}

.business-info .title-card p span {
  color: #1c2b36;
  font-weight: 600;
  display: block;
}

.business-info .title-card p span.dim {
  opacity: 0.4;
  font-size: 0.9rem;
}

.advance-detail .ant-collapse-borderless {
  background-color: #fff !important;
}

.advance-detail .ant-collapse-header span {
  color: #4b5660;
  font-weight: 600;
  display: block !important;
  flex: 0.5;
}

.advance-detail .ant-collapse-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  border-bottom: 1px solid var(--text-db);
}

.advance-detail .ant-collapse-header span:first-child {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center;
}

.advance-detail .ant-collapse-header span strong {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px solid #edf0f5;
  border-radius: 50%;
  font-weight: 600;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.advance-detail .ant-collapse > .ant-collapse-item {
  border-bottom: none !important;
}

.totalsSection {
  margin-right: 35px;
}

.totalsSection p {
  color: var(--text-primary);
  float: right;
  width: 354px;
}

.totalsSection span {
  color: var(--text-primary);
  float: right;
}

.totalBar {
  background: var(--text-f5);
  text-align: right;
  padding: 5px;
}

.totalBar p,
.totalBar span {
  color: var(--text-primary);
  margin-left: 70px;
}

.totalBar p {
  margin: 10px 30px;
}

.addComment input {
  width: 500px;
  border-radius: 6px;
}

.ant-steps-vertical.ant-steps-small
  .ant-steps-item-container
  .ant-steps-item-tail {
  padding: 23px 0 0px;
  top: -13px !important;
  height: 85px !important;
}

.popOvers {
  box-shadow: none !important;
  padding: 0px !important;
  border: none !important;
}

.ant-popover-inner {
  width: 100%;
}

.addExpComment input {
  width: 220px;
  border-radius: 6px;
}

.commentBody {
  color: var(--text-52);
  margin-bottom: 0px;
}

.commentData {
  color: var(--text-a4);
  font-size: 12px;
}

.commentsContent hr:last-child {
  display: none;
}

.policyVoiButton {
  margin: 18px 5px !important;
  border-radius: 6px !important;
}

.policyVoiButton span {
  color: #ff4d4f;
}

/* .imgWrapper span {
  color: #fff;
  background: red;
  width: 15px;
  height: 15px;
  border-radius: 20px;
  position: absolute;
  font-size: 10px;
  text-align: center;
  margin-left: 18px;
  margin-top: -8px;
} */

.reasonTextArea {
  margin-top: 1rem !important;
  resize: none;
}

.summaryCard {
  width: 92px;
}

.content2Wrapper {
  background: var(--text-white);
  border-radius: 5px;
  padding: 2rem 1rem 3rem 1rem;
}
