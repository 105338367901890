@media (min-width: 320px) and (max-width: 925px) {
  .mob-left-nav {
    min-height: 100vh;
    max-height: 100%;
    background-color: #fff;
    width: 85%;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    transition: all 0.3s ease; /* Optional: Adds a smooth transition effect */
    box-shadow: 10px 0 15px -5px rgba(0, 0, 0, 0.1); /* Add this line */
  }

  .mob-left-nav .nav-head {
    background: #f9f9f9;
    padding: 20px;
    position: relative;
  }

  .nav-head .close {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .nav-head .circle {
    background-color: #fff;
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nav-head .circle span {
    font-size: 1.7rem;
  }
  .nav-head .user-name {
    font-size: 1.7rem;
  }
  .nav-head .email {
    font-size: 1.3rem;
  }

  .mob-left-nav.hide {
    left: -100%; /* Moves the element completely off-screen to the left */
  }
}
