.typeDiv {
  border: 1px solid #d97d00;
  color: #d97d00;
  border-radius: 5px;
  text-align: center;
  padding: 4px 9px;
}
.viewPolicyCards {
  background: #f7f7f7;
  padding: 20px;
  border-radius: 9px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}
.darkbox {
  background: #d9d9d9;
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  padding: 0 10px;
  min-width: 140px;
  text-align: center;
  height: 45px;
  color: #464646;
  font-weight: 500;
  font-size: 1rem;
}
.lightbox,
.lightbox .ant-select-selection-search-input,
.lightbox .ant-input,
.lightbox .ant-select-selector {
  height: 45px !important;
}
.lightbox .ant-select-selector .ant-select-selection-item,
.lightbox .ant-select-selector .ant-select-selection-placeholder {
  line-height: 45px;
}

.lightbox .ant-select-selection-item-content {
  line-height: 22px;
}
/* .lightbox{
    background: #E5E5E5;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    display: inline-block;
    padding: 6px 10px;
    margin-left: 10px;
    min-width: 100px;
    width: 15rem;
    text-align: center;
} */

.viewPolicyCards .ant-select {
  width: 170px;
}

.viewPolicyCards .ant-input {
  width: 120px;
  margin-left: 10px;
  margin-right: 10px;
}
