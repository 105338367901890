.navigation-section {
  width: 22%;
  background: var(--primary-color);
  padding: 2rem 1rem;
  position: relative;
}

.navigation-section ~ .ant-layout {
  width: 78%;
  background-color: #f6f6f6;
}

.navigation-container {
  display: flex;
  position: sticky;
  top: 2rem;
  /* justify-content: center; */
}
.navigation-container .menu {
  width: 100%;
  max-height: 85vh;
  overflow-y: auto;
}
.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.menu-content {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 70%;
  height: 100%;
  background-color: #fff;
  z-index: 1001;
  overflow-y: auto;
}

.header-text {
  margin-left: 1rem;
}

.menu {
  padding-top: 1rem;
  text-align: left;
}

.list-section {
  list-style-type: none;
  padding-left: 20px;
  margin-bottom: 2rem;
  position: relative;
}

.list-section::after {
  height: 2.8rem;
  width: 1px;
  content: "";
  position: absolute;
  top: 1.2rem;
  border: 1px solid #ffffff50;
  left: 2.1rem;
  z-index: 1;
}

.list-section.completed-sec::after {
  border: 1px solid #ffffff;
}

.list-section:last-child:after {
  display: none;
}
.bg-circle {
  background: var(--primary-color);
}
.nested-list {
  margin: 1.2rem auto 1.5rem;
  padding-left: 11px;
}

.nested-list li {
  list-style-type: none;
  display: flex;
  align-items: center;
  border-left: 2px solid #cccccc;
  padding-left: 15px;
  height: 2.5rem;
  font-weight: 500;
  font-size: 1rem;
  color: #ffffff80;
}

.nested-list li.highlight {
  color: #ffffff;
}

.highlight {
  position: relative;
}
.header-circle {
  border-radius: 50%;
  border: 1px solid #fff;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.8rem;
}
.highlight::before {
  content: "";
  height: 2.5rem;
  border: 2px solid #ff9900;
  position: absolute;
  left: -0.8%;
  border-radius: 5px;
}

.header-text {
  font-size: 1.1rem;
  color: var(--text-white);
  font-weight: 600;
}

.check-icon {
  width: 20px !important;
  height: 9px !important;
  margin-right: 0.5rem !important;
}

.menu .close-icon {
  text-align: end;
  margin-bottom: 1.5rem;
}

.kyc-header .header-text {
  color: #293241;
  margin-left: 0;
  font-size: 2.5rem;
  font-weight: 600;
}

.kyc-header .sub-header-text {
  color: #293241;
  margin-left: 0;
  font-size: 1.1rem;
  font-weight: 500;
}
