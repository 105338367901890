h3 {
  margin-bottom: 0 !important;
}

.tabsSection span {
  color: #11264a;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0px;
  opacity: 1;
  font-family: "Figtree";
}

.tabsSection span.active {
  color: #170A33;
  font-weight: 700;
  position: relative;
}

.tabsSection span.active::after {
  width: 100%;
  content: "";
  height: 3px;
  background-color: #170A33;
  bottom: -1rem;
  position: absolute;
  left: 0;
}

.card-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  opacity: 1;
  padding: 2rem;
}

.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
}

.card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dedede;
  border-radius: 10px;
  opacity: 1;
  padding: 1.5rem;
}
.card-header span {
  font-size: 1.9rem;
  font-weight: 600;
  letter-spacing: 0px;
  color: #212121;
  opacity: 1;
}
.card-description {
  font-size: 1rem;
  letter-spacing: 0px;
  color: #212121;
  text-shadow: 0px 3px 16px #00000029;
  opacity: 0.59;
  word-wrap: break-word;
  max-width: 30rem;
}

.card-image {
  max-width: 18rem;
}

.cardImg {
  max-width: 15rem;
}

.card-points p {
  font-size: 1rem;
  letter-spacing: 0px;
  color: #212121;
  text-shadow: 0px 3px 16px #00000029;
  opacity: 1;
  /* min-width: 12rem; */
}

.card-with-image-points {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}

.card-points .icon {
  width: 1.2rem;
  height: 1.2rem;
}

.button-container {
  display: flex;
  justify-content: end;
}

.button-container button {
  background: #170A33 0% 0% no-repeat padding-box;
  border: 1px solid #170A33;
  border-radius: 8px;
  opacity: 1;
  color: #ffffff;
  font-size: 0.9rem;
  padding: 0.6rem 2rem;
  margin-right: 0.5rem;
}

.button-container .outlineButton {
  background: #f5f7fa 0% 0% no-repeat padding-box;
  border: 1px solid #170A33;
  border-radius: 8px;
  opacity: 1;
  letter-spacing: 0px;
  color: #170A33;
  padding: 0.6rem 2rem;
}

/* .status {
  background: #f9f0dc 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 0.4rem 0.3rem;
  min-width: 65px !important;
  opacity: 1;
} */

.status > span {
  text-align: center;
  font-size: 1rem;
  letter-spacing: 0px;
  color: #05152c;
  opacity: 0.5;
}

.card-features-section-elements p {
  font-size: 1.1rem;
  letter-spacing: 0px;
  color: #05152c;
  opacity: 0.5;
  margin-bottom: 0em !important;
}
.cardImage {
  max-width: 18% !important;
}

li {
  list-style: none;
}
.card-features-section {
  gap: 2rem !important;
}

.card-features-section-elements {
  margin-left: 0.2rem;
  margin-top: -0.1rem;
}
/* .card-points-description {
  margin-left: -0.3rem;
} */
/* .icon,
.rightArrowIcon {
  width: 1.7rem;
  height: 1.7rem;
  margin-right: 0.5rem !important;
} */

.card-features-section-elements label {
  font: 0.8rem;
  font-weight: 600;
  letter-spacing: 0px;
  color: #122549;
  opacity: 1;
}

.applyLoanCards-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.rightArrowIcon {
  display: flex;
  align-self: flex-end;
}
