.analytics-nav ul {
  width: 100%;
  list-style: none;
  display: flex;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
  align-items: center;
  padding: 0 1rem;
}

.analytics-nav ul li {
  padding: 1rem;
  color: #333;
  opacity: 0.4;
  position: relative;
  font-weight: 600;
  cursor: pointer;
}

.analytics-nav ul li.active {
  opacity: 1;
}

.analytics-nav ul li.active::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  height: 3px;
  background-color: var(--primary-color);
  width: 100%;
}

.ant-switch-checked {
  background-color: var(--primary-color) !important;
}

.reports .heading span {
  color: #4b5660;
  margin: 0 1rem;
}

.analytics-body {
  background-color: white;
  border: 1px solid #eaeaea;
  border-radius: 12px;
}

.reports .heading {
  padding: 1rem 1rem;
}

.reports .ant-divider-horizontal {
  margin: 0 !important;
}

.reports label {
  color: #4b5660;
}

.spend-card .title {
  color: #2f2f2f;
  font-size: 0.8rem;
}

.spend-card .amt {
  color: #2f2f2f;
  font-size: 1.7rem;
  font-weight: 600;
}

.spend-card .sub {
  color: #2f2f2f;
  opacity: 0.68;
  font-size: 0.7rem;
}

.spend-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  padding-left: 1rem;
}

.spend-card::after {
  position: absolute;
  content: "";
  height: 4rem;
  width: 1px;
  right: 4rem;
  top: 10px;
  background-color: #eaeaea;
}

.snap-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 2rem;
}

.snapshot .titles span:first-child {
  color: #4b5660;
}

.snapshot .titles span:last-child {
  color: var(--primary-color);
}

.snapshot .titles {
  margin: 0 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.snapshot {
  width: 38rem;
  margin-right: 3rem;
  margin-top: 3rem;
}

.card {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #eaeaea;
  border-radius: 17px;
}

.bal.card,
.send-payment.card {
  box-shadow: none;
}

.snapshot .card table {
  width: 100%;
}

.snapshot .card table thead tr th {
  background: #f5f7fa;
  color: #333333;
  font-weight: 600;
  padding: 0.8rem 0;
}

.snapshot .card table thead tr th:first-child {
  border-top-left-radius: 17px;
}

.snapshot .card table thead tr th:last-child {
  border-top-right-radius: 17px;
}

.snapshot .card table tbody tr td {
  color: #00000099;
  text-align: center;
  padding: 0.8rem 0;
}

.snapshot .card table tbody tr {
  border-top: 1px solid #eaeaea;
}

.report-table .ant-table-thead > tr > th {
  background: white;
  color: #2f2f2f;
  font-weight: 600;
  width: fit-content;
  max-width: 100%;
  white-space: nowrap;
  text-transform: capitalize;
}

.report-table.report-table-width .ant-table-pagination.ant-pagination {
  margin: 16px 10px;
}

.report-table .ant-table-thead > tr > th::before {
  display: none;
}

.report-table {
  overflow-x: auto;
}

.report-table.report-table-width .ant-table-content table {
  width: fit-content !important;
  min-width: 100%;
}

.report-table.report-table-width .ant-table-thead > tr > th {
  min-width: 10rem !important;
}

.customized-wrap .ant-select-selector {
  height: 3rem !important;
}

.fil-inputs .ant-picker {
  height: 3rem;
}

.inputSelect {
  position: relative;
  width: 100%;
}

.inputSelect .icon {
  position: absolute;
  right: -7px;
  color: #8994a5;
  background: #fff;
  top: 14px;
  width: 1.5rem;
}

.inputSelect .listOfItems {
  display: none;
  padding: 10px 7px;
  box-shadow: 0px 2px 7px #bbbbbb9e;
  border-radius: 6px;
  position: absolute;
  background: #fff;
  width: 100%;
  z-index: 10;
  max-height: 300px;
  overflow: auto;
}

.inputSelect .listOfItems:last-child {
  border: 0;
  padding: 0;
  margin: 0;
}

.inputSelect .listOfItems .isFlex {
  align-items: center;
  margin-bottom: 8px;
  font-family: "Avenir-Roman", sans-serif;
  border-bottom: 1px solid #dbe1e9cf;
  padding: 0.7rem;
}

.inputSelect .listOfItems .isFlex span {
  margin-left: 6px;
  max-width: 100px;
  cursor: pointer;
  color: #333;
}

.inputSelect .selectedItem {
  display: flex;
  /* position: relative; */
  align-items: center;
  padding: 10px 10px;
  border: 1px solid #dbe1e9;
  border-radius: 4px;
  height: 3rem;
  cursor: pointer;
}

.inputSelect .selectedItem span {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 350px;
  color: #333;
}

.inputSelect input[type="checkbox"] {
  --active: var(--primary-color);
  --active-inner: var(--primary-color);
  --focus: 2px rgba(39, 94, 254, 0.3);
  --border: #dbe1e9;
  --border-hover: var(--primary-color);
  --background: #fff;
  --disabled: #f6f8ff;
  --disabled-inner: #e1e6f9;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 21px;
  width: 21px;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: #fff;
  transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
}

input[type="checkbox"]:checked:after {
  width: 4px;
  height: 10px;
  border: 1px solid var(--active-inner);
  border-top: 0;
  border-left: 0;
  left: 7px;
  top: 2px;
  transform: rotate(43deg);
}

input[type="checkbox"]:after {
  content: "";
  display: block;
  left: 0;
  top: 0;
  position: absolute;
}

.inputSelect input[type="checkbox"]:hover:not(:checked):not(:disabled) {
  --bc: var(--border-hover);
}

.isInputField {
  border: 1px solid #dbe1e9;
  border-radius: 4px;
  color: #384150;
  font-size: 0.875rem;
  padding: 9px 13px;
  font-family: "Avenir-Roman", sans-serif;
  width: 100%;
}

.inputFieldHover:hover,
.inputFieldHover:focus-within {
  border: 1px solid var(--primary-color) !important;
  transition: 0.5s;
}

.filterTxt {
  color: #384150;
  font-size: 1rem;
}

.filterBtn {
  background-color: var(--primary-color);
  padding: 6px 12px;
  border-radius: 11.5px;
  position: relative;
  margin-left: 12px;
  display: inline-block;
  margin-top: 8px;
}

.filterBtn img {
  position: absolute;
  top: -5px;
  right: -15px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.fil-inputs .ant-select-item {
  color: #384150 !important;
}

.exportAs .ant-select-selector .ant-select-selection-placeholder {
  color: #4b5660 !important;
  opacity: 100% !important;
}
