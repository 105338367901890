.accountHead {
  background: #f7f7f7;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 35px 25px;
  margin-bottom: 10px;
}

.account-budget {
  margin-top: -15px;
  margin-right: 10px;
}
.account-budget .balanceTitle {
  margin-bottom: 0px;
}

.account-budget .balanceText {
  margin-top: 0rem;
  font-size: 17px;
  color: #ed3e43;
  font-weight: 600;
}

.anticon-more {
  font-size: 28px;
}

.accountNote {
  color: #587293;
}

.viewAccount {
  background: #f7f7f7;
  padding: 20px;
  border-radius: 9px;
  margin-top: 10px;
}

.viewAccount p {
  color: #adadad;
  margin-bottom: 2px;
  font-weight: 400;
  line-height: 28px;
}

.viewAccount span {
  color: #1e1e1e;
  font-weight: 600;
  line-height: 28px;
}

.viewAccount .notBold {
  color: #333333;
  font-weight: 500 !important;
}

.accountHead .moreDetails {
  cursor: pointer;
}

.main_section {
  background: #bfbfbf3d;
  padding: 10px;
  border-radius: 10px;
}
.main_section .header_section span {
  color: #000000d9;
  font-weight: 600;
  font-size: 12px;
}
.main_section .amount_main_section {
  margin-top: 10px;
  display: flex;
  align-items: baseline;
  gap: 10px;
}
.main_section .amount_section {
  color: #000000d9;
  font-weight: 600;
  font-size: 20px;
}
.main_section .text_section {
  color: #00000080;
  font-weight: 600;
  font-size: 10px;
}

/* account summary css */

.account-summary-card {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  padding: 20px;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.account-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.profile {
  display: flex;
  align-items: center;
}

.avatar {
  width: 50px;
  height: 50px;
  color: #b2b2b2;
  background: #6ae3c71c 0% 0% no-repeat padding-box;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.account-summary-card .acc-info {
  margin-left: 1rem;
  flex-direction: column;
  gap: 0;
}

.name {
  font-size: 1.3rem;
  font-weight: bold;
  color: #212121;
}

.email {
  font-size: 0.9rem;
  color: #122549;
  opacity: 0.6;
}
.account-summary-card .sub-acc-details,
.account-summary .acc-details {
  display: flex;
  gap: 4rem;
  width: 100%;
  margin-top: 1rem;
}

.account-summary .acc-details {
  margin-left: 0;
}

.account-summary-card .sub-acc-details .detail::after,
.money-summary .money::after,
.account-summary .acc-details .detail::after {
  content: "";
  border: 1px solid #efefef;
  height: 95%;
  position: absolute;
  right: -2rem;
  top: 5px;
}
.money-summary .money:last-child::after,
.account-summary-card .sub-acc-details .detail:last-child:after,
.account-summary .acc-details .detail:last-child:after {
  display: none;
}
.account-summary-card .detail,
.account-summary .detail div {
  display: flex;
  flex-direction: column;
  position: relative;
}
.account-summary .detail div {
  margin-left: 1rem;
}
.account-summary .detail {
  display: flex;
  align-items: center;
  position: relative;
}
.money-summary .money {
  position: relative;
  min-width: 12rem;
}
.account-summary-card .label,
.money-summary .label,
.account-summary .label {
  font-size: 0.9rem;
  color: #122549;
  opacity: 0.6;
  font-weight: 500;
}
.money-summary .value,
.account-summary-card .value,
.account-summary .value {
  font-size: 1rem;
  font-weight: 600;
  color: #212121;
}
.account-summary .value {
  display: flex;
}

.account-summary-card ~ .dashboard-title .card-title {
  font-size: 1.3rem;
  padding-top: 1.3rem;
  padding-left: 1rem;
  display: inline-block;
}

.account-summary h3 {
  color: #122549;
  opacity: 0.58;
  font-size: 1.2rem;
  font-weight: 600;
}

.money-summary {
  display: flex;
  gap: 4rem;
  padding: 0.8rem 1.7rem;
  border: 0.5px solid #e2dced88;
  border-radius: 6px;
}

.money-summary img {
  width: 1.3rem;
  margin-left: 2rem;
}

.account-summary-card .ant-select-selector {
  border-radius: 5px !important;
}

.txns {
  padding: 10px;
}

.txns h2 {
  font-weight: 600;
  color: #170a33;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  margin-bottom: 0.7rem;
}

.txns .line-item {
  margin-bottom: 0.7rem;
}

.txns .line-item:last-child {
  margin-bottom: 0;
}

.txns .line-item label {
  color: #11264a;
  font-weight: 500;
  font-size: 0.9rem;
}

.txns .amount {
  color: #11264a;
  font-size: 1rem;
}
