.setting-tabs .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab-btn p {
  color: var(--text-76) !important;
  font-size: 13px;
  display: flex;
}

.setting-tabs .tabCircles {
  background: #e4e4e4;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  text-align: center;
  padding-top: 11px;
  font-size: 18px;
  color: var(--text-primary);
  display: inline-block;
}

.setting-tabs .tabTitles {
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
}

.setting-tabs .ant-form-item {
  width: 90%;
}

.titleItem {
  width: 17% !important;
  display: inline-block !important;
  color: black;
}

.ant-select-selection-item {
  color: var(--text-primary);
}

.nameItem {
  width: 83% !important;
  display: inline-block !important;
}

.smallP {
  width: 90px !important;
}

.inlineItem {
  display: inline-block !important;
  color: var(--text-primary);
}

.levelsDiv input {
  width: 60%;
}

.levelsDiv .levelTitle {
  width: 80px;
}

.addLevelButton .anticon {
  float: initial !important;
}

.addLevelButton {
  padding: 0px !important;
}

.addLevelButton span {
  color: var(--text-ed);
}

.dynamicValues {
  margin-bottom: 10px !important;
}

.ant-select-multiple .ant-select-selection-item-content {
  color: var(--text-primary);
}

.instructions {
  padding: 0.5rem 3rem;
}
.instructions ul li {
  color: var(--text-primary);
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.title-select,
.title-select .ant-select-selector {
  min-height: 39.5px !important;
}
.title-select .ant-select-selector .ant-select-selection-item,
.title-select .ant-select-selector .ant-select-selection-placeholder {
  line-height: 39px;
}

.title-select .ant-select-selection-item-content {
  line-height: 22px;
  font-size: 14px;
}

.acc_access .title-sec label {
  font-weight: 600;
  font-size: 1.2rem;
}

.acc_access span {
  color: #212121;
}

.acc_access {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  margin-bottom: 2rem;
}
