.report-btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  position: relative;
}
.report-btns .close {
  position: relative;
  bottom: 0;
  top: 0;
  margin-left: 1rem;
}

.report-btns button {
  height: 42px;
  border-radius: 10px;
  font-weight: 500;
}
.report-btns .more {
  margin-right: 0;
}
.approve,
.reject,
.delete {
  color: white;
  min-width: 8rem;
  padding: 0 1rem;
}

.new-report-detail {
  background-color: white;
  border: 1px solid #dbdbdb;
  border-radius: 12px;
  margin-top: 1.5rem;
}

.new-report-detail .status {
  border-color: inherit;
  border: 1px solid;
  height: 35px;
  display: inline-flex;
  align-items: center;
}
.new-report-detail .item .status {
  border-color: none;
  border: none;
}

.report-head {
  padding: 2rem;
}

.new-report-detail .title-sec {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  font-size: 1.7rem;
  font-weight: bold;
}

.title-sec h3 {
  font-size: 1.7rem;
  margin-bottom: 0;
  color: #4b5660;
  font-size: 1.7rem;
  font-weight: bold;
}

.new-report-detail .sm {
  color: #1c2b36;
  opacity: 0.51;
  font-size: 0.9rem;
  font-weight: 500;
}

.new-report-detail .report-head p {
  margin-bottom: 0px;
  font-size: 0.95rem;
}

.new-report-detail p.duration {
  color: #2b2b2b;
  opacity: 0.9;
  margin-bottom: 5px;
  margin-top: 1rem;
}

.report-head strong {
  color: #1c2b36;
  font-weight: 600;
}

.tabs-sec {
  background: #F0FCF9 0% 0% no-repeat padding-box;
  border: 1px solid #edf5f4;
  padding: 0 2rem;
}

.report-head ~ .report-total {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
}

.report-total p {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.report-total p span.lg {
  font-size: 2rem;
  color: #1c2b36;
}

.new-report-detail .report-total {
  position: relative;
  padding: 0 3rem;
  margin-right: 2rem;
}

.new-report-detail .report-total::before {
  content: "";
  position: absolute;
  left: -2rem;
  top: 0;
  height: 6rem;
  width: 1.4px;
  background-color: #eaeaea;
}

.tabs-sec ul {
  list-style: none;
  display: flex;
  gap: 2rem;
  margin-bottom: 0;
}

.tabs-sec ul li {
  color: #4b5660;
  font-weight: 600;
  cursor: pointer;
  font-size: 1rem;
  padding: 1rem 0;
}

.tabs-sec ul li.active {
  position: relative;
  color: #212121;
}

.tabs-sec ul li.active::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3px;
  background-color: var(--primary-color);
}

.selected-tab {
  padding: 2rem;
}

.totalsSection,
.totalBar {
  color: #222222;
  font-weight: 600;
}

/* new Report Details  */
.details_p {
  display: flex;
  flex-direction: column;
}
.details_p .details_span.secondary {
  color: #1c2b36;
  opacity: 0.51;
  font-weight: 500;
}
.details_p .details_span.name {
  color: #4b5660;
  font-size: 1rem;
  font-weight: bold;
}

.details_div {
  background-color: rgb(247, 247, 247);
  border: 1px solid rgb(237, 240, 245);
  border-radius: 9px;
}

.timeline_div {
  margin-top: 3rem;
}
.details_div {
  padding: 1rem;
  min-height: 6rem;
}
.details_div p span {
  color: #4b5660;
  font-size: 1rem;
  font-weight: bold;
}
.details_div .details_span.secondary {
  color: #1c2b36;
  opacity: 0.51;
  font-weight: 500;
}
.my-custom-row {
  border-radius: 3rem;
}
