.new-bill-form {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  min-height: 640px;
  max-height: 650px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.new-bill-form .head,
.new-bill-form .sec-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  box-shadow: 0px 1px 6px #00000029;
  background-color: #ffffff;
  z-index: 100;
  position: sticky;
  top: 0;
}

.new-bill-form .head label {
  color: #3f81f4;
  padding: 1.6rem 2rem;
  display: block;
  font-weight: 600;
  border-bottom: 3px solid #3f81f4;
}

.new-bill-form .bottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 2rem;
  gap: 1.2rem;
  box-shadow: 0px 0px 6px #00000029;
  background-color: #ffffff;
  z-index: 100;
  position: sticky;
  bottom: 0;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cdcdcd;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
.new-bill-form table {
  width: 100%;
  border-radius: 7px;
  border-collapse: collapse;
}

.new-bill-form table tbody td {
  border: 1.6px solid rgba(149, 152, 154, 0.43);
  padding: 0.5rem;
  color: #454545;
  font-weight: 600;
}

.new-bill-form table thead {
  background: #e4e9f2;
  border-bottom: 1px solid #eaeaea;
}

.new-bill-form table thead tr th {
  color: #767676;
  padding: 1rem;
  font-weight: 600;
  text-align: left;

  /* min-width: fit-content; */
}

.new-bill-form table thead tr th:first-child {
  border-top-left-radius: 7px;
}

.new-bill-form table thead tr th:last-child {
  border-top-right-radius: 7px;
}

.new-bill-form .sub-total,
.apply-credit .sub-total {
  background: #f5f7fa;
  border: 1px solid #f5f5f5;
  border-radius: 9px;
  padding: 2rem;
  width: 56%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}
.apply-credit .sub-total {
  width: 70%;
  margin-top: 1rem;
}

.inv-discount .sub-total {
  width: 100%;
  margin-top: 1rem;
}
.inv-discount .sub-total .item {
  width: 100% !important;
}
.new-bill-form .link,
.report-head .link {
  color: #3f81f4;
  cursor: pointer;
  margin: 0.5rem;
  display: inline-block;
}

.new-bill-form .sub-total .item,
.apply-credit .sub-total .item,
.new-bill-form .total .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 76%;
  position: relative;
  margin-bottom: 10px;
}
.new-bill-form .total .item {
  width: 26%;
  margin-bottom: 0;
}
.new-bill-form .sub-total .item span,
.apply-credit .sub-total .item span,
.new-bill-form .total span {
  color: #222222;
}

.new-bill-form .sub-total .item input.discount {
  width: 5rem;
  position: absolute;
  left: -7rem;
  border: none;
  border-bottom: 1px solid #d9d9d9;
  background: transparent;
  padding: 0.3rem 0;
  padding-left: 0.5rem;
  top: -0.3rem;
  font-size: 14px;
  text-align: right;
}

.new-bill-form .sub-total .item .item-absolute {
  position: absolute;
  left: -4.5rem;
  border: none;
  padding: 0.3rem 0;
  padding-left: 0.5rem;
  top: 0.2rem;
}

.new-bill-form .total {
  background: #f5f7fa 0% 0% no-repeat padding-box;
  border: 1px solid #edf0f5;
  border-radius: 6px;
  padding: 0.7rem;
  margin-top: 1rem;
}

.ant-select-selection-placeholder {
  opacity: 0.5;
}

.new-bill-form .pp-dropdown .ant-select-selector,
.new-bill-form .pp-dropdown .ant-select-selection-item,
.new-bill-form .pp-dropdown input {
  height: 42px !important;
}

.items-table .ant-select-selector,
.items-table .pp-form-input,
.tds .ant-select-selector {
  border: none !important;
}
.tds .ant-select-selector {
  border-bottom: 1px solid rgba(149, 152, 154, 0.43) !important;
}
.tds.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
}
.items-table .ant-select-selection-placeholder {
  font-weight: 600;
  font-family: "Figtree", sans-serif;
  font-size: 15px !important;
  opacity: 1;
}

.items-table tr {
  position: relative;
}

.items-table tr img {
  position: absolute;
  top: 1.3rem;
  right: -2rem;
}
.items-table .pp-form-input {
  font-weight: 600;
}

.items-table .ant-select-selection-item {
  font-weight: 600;
  font-size: 15px !important;
}

.items-table td,
.items-table div.item {
  font-family: "Figtree", sans-serif;
  font-size: 15px !important;
}

div.item span {
  font-weight: 500;
}

.utility-bills {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  opacity: 1;
  border-radius: 10px;
  padding: 5rem 6rem;
}

.utility-bills .d-flex img {
  position: relative;
  left: 5rem;
  transform: scale(1.05);
}

.utility-bills .d-flex h4 {
  color: #363636;
  font-size: 1.8rem;
  font-weight: 600;
}
.center h4 {
  color: #363636 !important;
  font-size: 1.5rem;
  font-weight: 500;
}
.utility-bills .d-flex p {
  color: #6e6e6e;
  font-size: 1.1rem;
}

.utility-bills .line {
  width: 100%;
  margin: 2rem auto;
  height: 1px;
  background-color: #76767626;
}

.utility-bills label {
  color: #363636;
  font-weight: 600;
  margin-bottom: 1rem;
  display: inline-block;
}

.utility-bills span.link {
  color: #3f81f4;
  display: block;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.utility-bills .center {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  margin-right: 5rem;
  flex-direction: column;
  margin-top: 5rem;
}

.utility-bills .center img {
  transform: scale(1.5);
  margin-top: 3rem;
}

.ocr-upload {
  position: fixed;
  bottom: 3rem;
  right: 4rem;
  background-color: #fff;
  padding: 1rem;
  box-shadow: 0px 3px 12px #00000021;
  /* border: 1px solid #dededf; */
  border-radius: 7px;
  width: 25rem;
}

.ocr-upload span {
  color: #262626;
  font-weight: 600;
}

.ocr-upload .title-sec {
  margin-bottom: 1.2rem;
}
.ocr-upload .file-list {
  list-style: none;
}

.ocr-upload .file-list li p {
  color: #767676;
  font-size: 0.9rem;
}
.ocr-upload .file-list li {
  margin-bottom: 1.5rem;
}

.ocr-upload .file-list li:last-child {
  margin-bottom: 0;
}
.ocr-upload .upload-status {
  height: 1.5px;
  background-color: #e3e9f3;
  border-radius: 6px;
  position: relative;
  z-index: 10;
}

.ocr-upload .upload-bg-status {
  height: 1.5px;
  border-radius: 6px;
  position: absolute;
  background-color: #3f81f4;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
}
.ocr-upload li img {
  display: none;
}

.ocr-upload li.done img {
  display: block;
}
.ocr-upload .done .upload-bg-status {
  background-color: #00aa34;
}

.ocr-upload .link {
  font-weight: 400;
  color: #3f81f4;
  font-size: 0.8rem;
  margin-left: 1rem;
  cursor: pointer;
}

.orc-modal {
  padding: 0.5rem;
}

.orc-modal .head-sec {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 7px;
}

.orc-modal ul {
  list-style: none;
  display: flex;
  padding: 0rem 1.5rem;
  margin-bottom: 1.5rem;
}

.orc-modal li {
  font-weight: 600;
  margin-right: 1rem;
  position: relative;
  padding: 1rem;
  cursor: pointer;
}
.orc-modal li.ocr-progress .badge,
.orc-modal li.failed .badge {
  background-color: #3f81f4;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  min-width: 25px;
  font-size: 0.8rem;
  border-radius: 36px;
  margin-left: 0.5rem;
  text-align: center;
}

.orc-modal li.failed .badge {
  background-color: #d14046;
}

.orc-modal li.ocr-progress.active {
  color: #3f81f4;
  border-bottom: 3px solid #3f81f4;
}

.orc-modal li.failed.active {
  color: #d14046;
  border-bottom: 3px solid #d14046;
}

.scans .item {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 7px;
  padding: 1.5rem 2rem;
  margin-bottom: 2rem;
}
.scans .item.done {
  background: #fffff4 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
}
.scans .item .img-sec {
  /* max-height: 290px; */
  min-height: 280px;
  width: 100%;
  background-color: #fff;
  margin-bottom: 1.5rem;
}

.scans .item p {
  color: #363636;
  font-weight: 600;
  margin-bottom: 0;
}

.scans .item p.dim {
  color: #767676;
  font-size: 0.9rem;
  font-weight: 600;
  margin-top: 3px;
}

.scans .item .hr {
  border-bottom: 1px solid #dbdbdb80;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.scans .trash {
  display: flex;
  padding: 10px;
  background-color: white;
  border: #e4e4e4 1px solid;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.man-btn {
  background: #e2ecff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #3f81f4;
  padding: 0.5rem 1.5rem;
}

.scans p.success {
  color: #00aa34;
}

.scans p.category {
  color: #3f81f4;
}

.scan-failed,
.scan-progress {
  padding: 0.5rem 1rem;
  margin-bottom: 2px;
}

.scan-failed .link,
.scan-progress .link {
  color: #3f81f4;
}
.scan-failed p,
.scan-progress p {
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.scan-progress {
  color: #3f81f4;
  background-color: #eaf8ff;
}
.scan-failed {
  color: #d14046;
  background-color: #faeef1;
}

.scan-failed p img,
.scan-progress p img {
  margin-right: 0.5rem;
}
.items-table .ant-select-selection-item {
  white-space: normal !important;
}
.strip {
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 10px;
}

.strip.red {
  background-color: #d14046;
  color: #faeef1;
}
.strip.green {
  background-color: #208f1e;
  color: #faeef1;
}
.report-head .ant-collapse-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center !important;
}
.report-head .ant-collapse-header p {
  color: #222222;
}
.report-head .ant-collapse-arrow {
  flex: 0.5;
  display: flex !important;
  justify-content: flex-end !important;
  text-align: right;
}

.report-head .ant-collapse-header p {
  text-align: left;
  flex: 0.5;
  display: flex;
  justify-content: flex-start;
}

.inv-discount .sub-total {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
}

.bigFont {
  font-size: 1.3rem;
  font-weight: 700;
}

.bigFont span:last-child {
  font-weight: 700;
  font-size: 2rem;
}

.smFont {
  font-size: 0.9rem;
}
