.login-page {
  background: #f5f7fa 0% 0% no-repeat padding-box;
  min-height: 100vh;
  width: 100%;
}

.login-container {
  /* padding: 3rem 6rem; */
  display: flex;
}

.login-left {
  background: #f5f7fa;
  padding: 3rem 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-left.background-color {
  background: #ffffff;
}

.login-form .ant-typography,
.login-form label,
.login-form a span,
.footer-note,
.or,
.login-form label span {
  color: #212121 !important;
}

.login-form h3 {
  font-size: 2.5rem !important;
  margin-bottom: 5px !important;
}

.login-form input {
  height: 42px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 6px;
}

.login-form .ant-form-item-label {
  color: #212121;
  font-weight: 600;
  font-size: 1.1rem;
}

.login-footer .login_terms {
  color: var(--primary-color) !important;
  text-decoration-line: underline;
}

.login-form .ant-input-affix-wrapper {
  padding: 0 11px !important;
  border-radius: 6px;
}

.emp-text {
  color: var(--primary-color);
  font-weight: 600;
}

.head-sec h3 {
  font-size: 2.5rem;
}

.head-sec h4 {
  font-size: 2rem;
}

.head-sec p {
  margin-top: 0.5rem;
}

/* .head-sec {
  margin-bottom: 3rem;
} */

.footer-note {
  font-size: 0.8rem;
}

.login-right {
  background: url(../../assets/images/left_bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  align-items: center;
  /* position: relative; */
  position: sticky;
  top: 0;
}

.login-right:after {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), black), transparent;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.login-right h3 {
  color: var(--text-primary);
  font-size: 2.3rem;
}

.login-right p {
  color: var(--text-secondary);
  font-size: 1.2rem;
}

.icon-background {
  background: #656565 0% 0% no-repeat padding-box;
  border-radius: 18px;
  width: 65px;
  height: 65px;
}

.icon-background img {
  margin: 16px;
  width: 35px;
}

.loginButton {
  border-radius: 0.3rem !important;
  width: 7rem;
  height: 42px;
  font-weight: 600;
}

.login-form
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

.flex-center {
  width: 26rem;
}

.bulk-users .flex-center {
  width: 100%;
}

.login-right-content h2,
.login-right-content span {
  color: white !important;
  display: block;
  /* width: 70%; */
}

.login-right-content h2.ant-typography {
  margin-bottom: 1rem;
}

.login-right-content span {
  /* width: 40%; */
  margin-bottom: 2rem;
}

.login-right-content {
  padding: 0 5rem;
}

.login-right-content img {
  width: 100%;
}

.signup-form {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-areas:
    "item0 item1"
    "item2 item2"
    "item3 item3"
    "item4 item5"
    "item6 item6"
    "item7 item7"
    "item8 item8"
    "item9 item9";
  gap: 1.2rem;
}

.business-main-container .signup-form {
  display: block;
  padding: 2rem;
}

.signup-form .pp-form-item .pp-form-input {
  background: #ffffff !important;
  border: 0.75px solid #d9d9d9 !important;
  border-radius: 6px;
  margin-top: 5px;
}

.mobile_number .pp-form-input {
  padding-left: 120px;
}

.ant-select-focused .ant-select-selector,
.ant-select:focus .ant-select-selector,
.ant-select:active .ant-select-selector {
  box-shadow: none !important;
}

.signup-form .pp-dropdown .ant-select-selector {
  background: #ffffff !important;
  border: 0.75px solid #d9d9d9 !important;
  border-radius: 6px;
  height: 2.7rem !important;
}

.signup-form .pp-dropdown .ant-select-selector input,
.signup-form .ant-select-selection-placeholder,
.signup-form .ant-select-selection-item {
  height: 2.7rem !important;
}
.mobile_number {
  position: relative;
  width: 100%;
}

.mobile_number .pp-dropdown {
  position: absolute;
  bottom: 0;
  z-index: 10;
  width: 110px !important;
}

.mobile_number .pp-dropdown .ant-select-selector {
  background: #fafbfd 0% 0% no-repeat padding-box !important;
  border: none !important;
  border-right: 0.75px solid #d9d9d9 !important;
  border-radius: 0;
  margin-left: 4px;
  margin-bottom: 1px;
  width: 110px !important;
}

.mobile_number .pp-dropdown .ant-select-selector {
  width: 60px;
}
.signup-form .form-label {
  margin-bottom: 9px;
  display: inline-block;
}

.right_content {
  position: absolute;
  bottom: 3rem;
  z-index: 100;
}
.right_content p.title {
  font-weight: 600;
  font-size: 2.4rem;
  margin-top: 1rem;
  letter-spacing: 0px;
  color: #fff;
  max-width: 80%;
  margin-bottom: 1rem;
}
.right_content p.sub-text {
  font-weight: 500;
  font-size: 1.1rem;
  color: #ffffff !important;
  position: relative;
  max-width: 85%;
  z-index: 10;
  opacity: 0.6;
}

.right_content span::before {
  left: -15px;
  top: 0;
  content: "";
  position: absolute;
  width: 110%;
  height: 100%;
  background: #fae38a;
  transform: skewX(-15deg);
  z-index: -1;
}

/* Assign each item to the correct grid area */
.item-0 {
  grid-area: item0;
}

.item-1 {
  grid-area: item1;
}

.item-2 {
  grid-area: item2;
}

.item-3 {
  grid-area: item3;
}

.item-4 {
  grid-area: item4;
}

.item-5 {
  grid-area: item5;
}

.item-6 {
  grid-area: item6;
}
.item-7 {
  grid-area: item7;
}

.item-8 {
  grid-area: item8;
}

.item-9 {
  grid-area: item9;
}
@media (max-width: 768px) {
  .login-container {
    padding: 1rem;
    flex-direction: column;
  }

  .login-container .w40,
  .login-container .w60 {
    width: 100%;
  }

  .login-right {
    margin-left: 0;
  }

  .login-left {
    padding: 2rem;
  }

  .flex-center {
    width: 100%;
  }
}

.rtl .ml2 {
  margin-right: 20px;
}

/* onboard sign up media quary */
.login-right-section {
  width: 70%;
}

/* .login-right-section .login-right-content {
  width: 70%;
} */

@media (min-width: 1023px) and (max-width: 1200px) {
  .login-right-section {
    width: 80%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .login-right-section {
    width: 100%;
  }

  .login-right-section .pp-form-item.field_length {
    width: 100%;
  }
}

@media screen and (max-width: 887px) {
  h2.ant-typography {
    font-size: 25px !important;
  }
}

@media screen and (max-width: 848px) {
  h2.ant-typography,
  h3.ant-typography {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 838px) {
  h2.ant-typography,
  h3.ant-typography {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 768px) {
  .login-image-section,
  .login-left.p-relative {
    width: 100% !important;
  }

  .login-right-section {
    width: 70% !important;
  }

  .pp-form-item.field_length {
    width: 100% !important;
  }
}

@media screen and (max-width: 576px) {
  h2.ant-typography,
  h3.ant-typography {
    line-height: 1 !important;
  }

  .login-right-section {
    width: 100% !important;
  }

  .login-right-content {
    padding: 0px 3rem !important;
  }

  .login-right {
    min-height: 65vh !important;
  }

  .otpInputBlock .otpInputField {
    margin-right: 5px !important;
  }

  .signup-container .check-box-button .checkbox-group {
    grid-template-columns: 1fr !important;
  }

  .signup-container .check-box-button .text-div {
    font-size: 0.8rem !important;
  }

  .signup-container .check-box-button .ant-checkbox-group label {
    width: 18rem !important;
    height: 5.5rem !important;
    padding-right: 1rem;
  }

  .signup-container .check-box-button {
    grid-template-columns: 1fr !important;
  }

  .head-sec {
    margin-bottom: 0.5rem;
  }
}

@media (min-width: 1430px) and (max-width: 1550) {
  .login-form h3 {
    font-size: 2rem !important;
  }
}
