.showcase-box {
  border: 2px solid var(--text-d6);
  border-radius: 4px;
  width: 400px;
  padding: 0 10px;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  display: inline-flex;
  cursor: pointer;
  min-height: 9rem;
  max-height: 9rem;
  position: relative;
}

.ant-steps-item-icon {
  /* background: #e4e4e4 !important; */
  border: none !important;
}

.ant-checkbox-inner::after {
  border-color: #fff !important;
}

/* .ant-steps-item-finish .ant-steps-item-icon,
.ant-steps-item-active .ant-steps-item-icon {
  background: #ff9300 0% 0% no-repeat padding-box !important;
} */
.text-orginal {
  color: #ff9300;
}

.ant-steps-item-icon .ant-steps-icon {
  color: #333333 !important;
}

.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon,
.ant-steps-item-active .ant-steps-item-icon .ant-steps-icon {
  color: #fff !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #ff9300 !important;
}

.showcase-box a:hover {
  color: #333333 !important;
  /* color: #1890ff !important; */
}

.showcase-box a {
  color: #333333 !important;
}

.showcase-box:hover {
  background-color: #fafafa;
}

.showcase-box-dashed {
  border: 2px dashed var(--text-d6);
}

.transferText {
  width: 500px !important;
}

.rightContentTransfer {
  border-left: 1px solid var(--text-d9);
  padding: 0px 20px;
}

.arrowBackground {
  width: 30px;
  height: 30px;
  background: #fafafa;
  border-radius: 5px;
  border: 1px solid #dddddd;
  text-align: center;
  padding-top: 3px;
  margin: 0px auto;
}

.quickLoadHead {
  background: #f7f7f7;
  padding: 20px;
  border-radius: 9px;
}

.quickLoadHead p {
  color: #adadad;
  margin-bottom: 2px;
  font-weight: 400;
}

.quickLoadHead span {
  color: #1e1e1e;
  font-weight: 600;
}

.map-fields {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.map-fields select {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  height: 2.5rem;
  min-width: 20rem;
}

.fields .header {
  background: #e4e9f2 0% 0% no-repeat padding-box;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  padding: 0.7rem 2rem;
  margin-bottom: 1rem;
}

.fields .header div {
  color: #333333;
  font-weight: 600;
}

/* Bulk Transfers */
.upload_payment_parent,
.upload_parent {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  min-height: fit-content;
  max-height: fit-content;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  margin-top: 3rem;
  min-height: 70vh;
}

.step_div {
  margin: 2.8rem 0 3.75rem 15.6rem;
  width: 60%;
}

.step_div .ant-steps-item-wait .ant-steps-item-icon {
  background: #e4e4e4 !important;
}

.upload_payment_parent .ant-steps-item-finish .ant-steps-item-icon {
  background: #2cc426 !important;
}

.pp-form-item.field_length {
  width: 31.25rem;
}

.upload_payment_form {
  margin: 1rem 0 1.1rem 2rem;
}

.note_span {
  color: #58666e;
  opacity: 0.46;
  font-size: 1rem;
  font-weight: normal;
}

.right_div {
  margin: 7rem 0 0 8rem;
  display: flex;
  flex-direction: column;
}

.right_div .right_header {
  font-weight: normal;
  color: #262626;
  opacity: 1;
  font-size: 1.25rem;
}

.right_div .right_span {
  font-weight: normal;
  color: #262626;
  opacity: 0.46;
  font-size: 1rem;
  max-width: 70%;
}

.right_div .or {
  font-weight: normal;
  color: #262626;
  margin: 1rem 0;
  font-size: 1.1rem;
}

.right_div .right_button {
  width: 18.12rem;
  height: 3.1rem;
  margin-top: 1rem;
  border-radius: 5px;
  border: 1px solid #6ae3c6;
  cursor: pointer;
}

.right_div a.ant-btn {
  padding: 4px 15px !important;
}

.right_div .right_button span {
  color: #6ae3c6;
  font-weight: medium;
}

.btn_div {
  display: flex;
  margin-left: 2rem;
  margin-bottom: 4.18rem;
}

.center_line {
  position: absolute;
  width: 0.007rem;
  height: 63%;
  background: #58666e;
  opacity: 0.46;
  top: 25%;
  left: 55%;
}

/* second upload div css */
.upload_payment_div {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  min-height: fit-content;
  max-height: 650px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.upload_payment_table {
  display: flex;
  margin: 2.75rem auto 1.5rem;
  width: 95%;
}

.myspace {
  gap: 0px !important;
}

/* create payment css start  */
.amount_div div {
  padding-top: 1.2rem;
}

.link_div {
  font: 100;
}

.link_header {
  font-size: 1.15rem;
  margin-top: 1.5rem;
  color: #d8d8d8;
}

.link_div .link_display {
  float: left;
  color: rgba(0, 0, 0, 0.8);
  margin: 5px 0px;
  padding: 1px;
  width: 29.3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 17px;
  padding-left: 3rem;
}

.share_link {
  margin-top: 5rem;
  font-size: 1.15rem;
}

.customer_div {
  padding: 0 0 0 4rem;
}

.customer_header {
  font-size: 1.1rem;
  margin-bottom: 0px;
}

.company_name {
  font-size: 1.1rem;
  padding-left: 1rem;
}

.link_line {
  border: 1px solid #d8d8d8;
  width: 66%;
  margin-left: 2rem;
  opacity: 0.3;
}
.upload_file .upload_buttom:hover {
  border-color: #d9d9d980;
}
.upload_file .upload_buttom {
  width: 18.12rem;
  height: 3.1rem;
  margin-top: 1rem;
  border-radius: 5px;
  /* border: 1px solid #3f81f4; */
  cursor: pointer;
  display: flex;
  align-items: center;
}
