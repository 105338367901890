.custom-tabs .ant-tabs-nav {
  background: #fff;
  border: 1px solid var(--text-db);
  border-radius: 12px;
  height: 45px;
}

.userContentWrapper .custom-tabs .ant-tabs-nav {
  background: #fff;
  border: none;
  height: 45px;
}

.userContentWrapper .ant-tabs-content-holder {
  padding: 1rem 3rem 3rem 3rem;
}

.customfield-text {
  width: 5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-tabs .ant-tabs-nav-list {
  margin-left: 15px;
}

.custom-tabs .ant-tabs-nav .ant-tabs-tab {
  border: none !important;
  background: transparent !important;
}

.custom-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  color: var(--text-primary);
  opacity: 0.5;
}

.custom-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--text-primary);
  opacity: 1;
}

.custom-tabs .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom: 4px solid orange !important;
}

.tabContentWrapper {
  /* background: #fff; */
  padding: 2rem 3rem 3rem 2rem;
  /* border: 1px solid var(--text-db); */
}

.tabContentWrapperSmall {
  background: #fff;
  border-radius: 0.5rem;
  padding: 1.5rem 3rem 1rem 2rem;
  border: 1px solid var(--text-db);
}

.userContentWrapper {
  background: #fff;
  border-radius: 0.5rem;
  border: 1px solid var(--text-db);
}

.userContentWrapper p {
  color: var(--text-primary);
}

.tabContentWrapperSmall h3 {
  margin-bottom: 0px;
}

.custom-form .ant-checkbox-wrapper {
  float: left;
}

.custom-form p {
  color: var(--text-primary) !important;
  width: 100%;
  display: inline-block;
  font-size: 14px;
}

.custom-form .ant-checkbox-wrapper span,
.custom-form .ant-radio-wrapper span,
.custom-form .ant-form-item-label label {
  color: var(--text-primary) !important;
}

.custom-form .ant-divider-horizontal {
  margin: 6px 0 12px 0;
}

.custom-form .ant-form-item {
  margin-bottom: 10px;
}

.custom-form .customTitle {
  margin-top: 20px;
}

.custom-form .shortNumberInput {
  margin-top: -5px;
}

.custom-form .shortInput {
  width: 55px;
  border-radius: 5px;
}

.expForm .ant-input {
  width: 300px;
  border-radius: 4px;
}

.expForm .ant-select-selector {
  border-radius: 4px;
}

.flexDisplay {
  display: flex;
}

.small-width {
  width: 70%;
}

.inlineItem {
  display: inline-block !important;
  color: var(--text-primary);
}

.fieldsDiv input {
  width: 270px;
}

.fieldsDiv .levelTitle {
  width: 80px;
}

.addFieldButton .anticon {
  float: initial !important;
}

.addFieldButton {
  padding: 0px !important;
}

.addFieldButton span {
  color: var(--text-ed);
}

.optionsContainer {
  background: var(--text-f8);
  padding: 20px;
  border-radius: 6px;
}

.previewDiv {
  background: var(--text-f8);
  padding: 20px;
  border-radius: 6px;
}

.optionsSort {
  float: right;
}

.optionsSort span {
  color: red;
}

.custom_module {
  width: 100%;
}

.container_module {
  background: #fff;
  border-radius: 0.5rem;
  border: 1px solid var(--text-db);
}

.container_module .module_header {
  display: flex;
  flex-direction: row;
  background: #e4e9f2;
  padding: 1rem 0rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.module_header .left_header {
  font-size: 1.2rem;
  font-weight: 600;
  color: #767676;
  opacity: 1;
  width: 25.3rem;
  padding-left: 8rem;
}

.module_header .right_header {
  width: fit-content;
  font-size: 1.2rem;
  font-weight: 600;
  color: #767676;
  opacity: 1;
}

.module_content .modules_submenu {
  padding: 2.1rem 0rem;
}

.modules_submenu .submenu_header {
  font-size: 1.1rem;
  font-weight: 600;
  color: #333333;
  text-transform: uppercase;
  opacity: 0.48;
  padding-left: 8rem;
}

.modules_submenu .submenu_content {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(225, 225, 225, 0.5);
  padding: 1.5rem 0rem;
  cursor: pointer;
  transition: 0.5s;
}

.modules_submenu .submenu_content:hover {
  background: rgba(225, 225, 225, 0.3);
}

.submenu_content .submenu_content_circle {
  width: 3.6rem;
  height: 3.6rem;
  background: #e9fbf7;
  border-radius: 50%;
  margin-right: 2rem;
}

.submenu_content .submenu_content_wrapper {
  display: flex;
  width: 25.3rem;
  padding-left: 8rem;
  align-items: center;
}

.submenu_content .submenu_content_header {
  font-size: 1rem;
  color: #363636;
  opacity: 1;
  font-weight: 500;
}

.blur-color .ant-select-selection-item {
  color: #00000040;
}

.option.set_default {
  border: none;
  background: #fff !important;
  outline: none;
  box-shadow: none;
}

.option.set_default span {
  color: #000;
}
