@media (min-width: 320px) and (max-width: 925px) {
  .recent-transaction {
    display: flex;
    align-items: center;
    background: #fafafa;
    padding: 16px;
    justify-content: space-between;
  }
  .recent-transaction.table-sec {
    background: #fff;
    border-radius: 6px;
    margin-bottom: 10px;
    padding: 10px;
  }
  .ant-pagination-total-text {
    display: block !important;
  }
  .recent-transaction:hover {
    background: #e9fbf772;
  }
  .recent-transaction .tx-det {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .recent-transaction .avatar {
    width: 4.5rem;
    height: 4.5rem;
    background: #d0d8ff;
    color: #3659b5;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 16px;
    margin-right: 10px;
  }
  .recent-transaction .ellipsis {
    max-width: 14rem;
    display: inline-block;
  }
  .recent-transaction .date-time {
    font-size: 12px;
    color: #777;
  }
  .recent-transaction .title {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .recent-transaction .amount {
    font-size: 14px;
    font-weight: 600;
    color: #333;
  }
  .footer-contact.phone .card-title {
    display: none;
  }
  .dashboard-title {
    align-items: center;
  }
  .title-summary {
    font-size: 16px;
  }
  .acc-header {
    flex-direction: column-reverse;
    gap: 1rem;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
  .acc-wrap {
    grid-template-columns: 1fr;
  }
  .select-statement .w60 {
    width: 100% !important;
  }
  .select-statement .ant-space {
    display: flex;
    flex-wrap: wrap;
  }
  .ellipsis {
    max-width: 13rem;
  }
}
