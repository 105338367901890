.send-payment label,
.send-payment .ant-radio-group span,
.ant-input-prefix {
  color: #2e2e2e;
}
.send-payment .ant-input-prefix {
  margin-right: 10px;
}
.ends {
  display: flex;
  flex-direction: column;
}

.new-bill-form .sec-head,
.send-payment .sec-head {
  padding: 1.2rem 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.new-bill-form .tabsSection,
.send-payment .tabsSection {
  display: flex;
  gap: 2rem;
}
.new-bill-form .tabsSection span,
.send-payment .tabsSection span {
  cursor: pointer;
}
.new-bill-form .tabsSection span.active::after,
.send-payment .tabsSection span.active::after {
  background-color: #6ae3c6;
  bottom: -1.3rem;
}
.send-payment
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after,
.send-payment
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon {
  background: #6ae3c6;
  background-color: #6ae3c6 !important;
}
.send-payment .ant-steps-item-icon {
  width: 2rem;
  height: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
}
.send-payment .ant-steps-item-title {
  font-size: 1rem;
}

.send-payment .ant-steps {
  width: 60%;
  margin: auto;
}

.step_parent.send-payment .ant-steps {
  width: 80%;
  margin: auto;
}

.send-payment.hirarchy .ant-steps {
  width: 100%;
}

.step-2.ant-steps-item {
  flex: 0.7 0.5;
}

.send-payment .ant-steps-item-wait .ant-steps-item-icon {
  background: #f0f5f4 0% 0% no-repeat padding-box;
}

.send-payment .ant-steps-item-wait .ant-steps-item-icon span {
  color: #333333;
  opacity: 0.6;
}

.send-payment .bank-details {
  width: 100%;
  background: #fafbfd 0% 0% no-repeat padding-box;
  border: 1px solid #edf0f5;
  padding: 20px;
  border-radius: 9px;
  margin-top: 1rem;
}

.send-payment .nickname,
.send-payment .legal-name {
  margin-bottom: 15px;
}

.send-payment .label {
  display: block;
  color: #8b8b8b;
  font-size: 0.9rem;
  margin-bottom: 3px;
}

.bal-details .value,
.send-payment .value {
  font-size: 1rem;
  color: #3c4853;
  font-weight: 600;
  margin-bottom: 6px;
}

.send-payment .bank-info {
  background: #fafbfd 0% 0% no-repeat padding-box;
  border: 0.75px solid #e9eaeb;
  border-radius: 9px;
  padding: 10px;
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.send-payment .acc-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.send-payment .bank-name {
  display: flex;
  align-items: center;
  gap: 8px;
}

.send-payment .bank-icon {
  width: 2.8rem;
  height: 2.8rem;
}

.send-payment .account-details,
.send-payment .bank-location {
  color: #7d7d7d;
  font-size: 14px;
}

.send-payment .payment-details {
  width: 50%;
  padding: 20px;
  border-radius: 10px;
  margin-top: 4rem;
  border: 0.75px solid #e9eaeb;
  background-color: #fff;
}

.section-header {
  margin-bottom: 20px;
}

.section-header h2,
.payment-container h2 {
  font-size: 20px;
  margin-bottom: 5px;
  color: #333;
}

.section-header p {
  font-size: 1rem;
  color: #848485;
  margin-bottom: 0;
}

.amount {
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

.details {
  margin-bottom: 20px;
}

.detail-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.memo {
  background-color: #fafbfd;
  padding: 1rem;
  border-radius: 8px;
  border: 0.75px solid #e9eaeb;
  margin-bottom: 20px;
}

.memo-label {
  display: block;
  color: #7d7d7d;
  font-size: 14px;
}

.memo-value {
  color: #333;
  font-size: 16px;
  font-weight: bold;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.send-payment .payment-details .value {
  text-align: right;
}

.email-receipt {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 1rem;
}

.email-receipt label {
  color: #3b3b3b;
  font-size: 0.9rem;
}
.email-receipt input {
  padding: 10px;
  border: none;
  border-bottom: 1px solid #e9eaeb;
}
.send-payment .payment-container {
  margin-top: 3rem;
  margin-left: 2rem;
}

.send-payment .payment-container .payment-details {
  margin-top: 2rem;
}

.send-payment .ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.font-big {
  font-size: 2.2rem !important;
}

.send-payment .item {
  margin-bottom: 1rem;
  position: relative;
}
.send-payment .acc-img{
  width: 2.7rem;
}
.send-payment.list .item {
  margin-bottom: 0;
}
.send-payment .item .value.font-big{
  min-height: 3.45rem;
  margin-bottom: 0;
}
.send-payment .item .value {
  padding: 0;
  display: inline-flex;
  align-items: center;
}

.send-payment .ant-btn > span {
  color: #6ae3c6;
}

.send-payment .ant-btn {
  display: flex;
  align-items: center;
  gap: 7px;
}

.send-payment .ant-col .ant-card {
  max-height: 23.2rem;
  min-height: 21rem;
}
.value.status {
  left: 0.7rem;
}
.value.status::before {
  left: -0.7rem;
}
.item .right {
  position: absolute;
  right: 0;
  top: 4px;
  width: 0.6rem;
}
.right span {
  color: #2121219b !important;
  display: block;
  text-align: right;
}
.right label {
  color: #2121219b !important;
  min-width: 6rem;
  display: inline-block;
}
.account-details .bal-sec {
  margin: 0;
}

.sec-btn {
  height: 39px;
  padding: 0;
  margin: 0;
  padding: 0 1rem;
  background-color: #fff;
  border-radius: 4px;
}

.sec-btn svg {
  fill: #6ae3c6;
  transform: scale(1.4);
}

.send-payment .action-btns {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 3rem;
}

.send-payment .ant-select-selection-item {
  font-weight: 500;
}
.send-payment.add-user .ant-steps {
  width: 55%;
}
.payment-details-container .details-card {
  background: #fafbfd 0% 0% no-repeat padding-box;
  border: 0.75px solid #e9eaeb;
  border-radius: 9px;
  padding: 1rem;
  margin-top: 3rem;
}

.payment-details-container .amount {
  margin-top: 1rem !important;
}

.imgs-wrap {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
}

.imgs-wrap .img {
  width: 10rem;
  height: 11rem;
  position: relative;
  background: #e3e3e3 0% 0% no-repeat padding-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgs-wrap img {
  width: 80%;
  height: 100%;
}

.file-close {
  position: absolute;
  top: -5px;
  right: -15px;
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.file-eye {
  position: absolute;
  width: 2rem !important;
  height: 1.5rem !important;
  display: none;
  cursor: pointer;
  z-index: 100;
}
.file-download {
  position: absolute;
  width: 1.5rem !important;
  height: 1.5rem !important;
  display: none;
  cursor: pointer;
  z-index: 100;
}
.img:hover .file-eye,
.img:hover .file-download {
  display: inline;
}
.img:hover::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 8px;
  background-color: #00000020;
}

.additional .img:hover::after {
  display: none;
}

.send-payment.list .account-row {
  margin-bottom: 1rem;
  margin-right: 1rem;
  cursor: pointer;
}

.send-payment.list .ant-card-body::before {
  display: none;
}

.send-payment.list .ant-card-body {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 1rem;
  padding: 1.2rem !important;
}
.send-payment.list .ant-col .ant-card {
  max-height: 7rem;
  min-height: 7rem;
}

.email-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.email-tag {
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  background-color: #e0e0e0;
  border-radius: 15px;
  font-size: 0.9em;
}

.remove-btn {
  cursor: pointer;
  margin-left: 5px;
  color: #888;
}

.back-link {
  color: #284d75;
}

.info {
  display: flex;
  gap: 0.3rem;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.55);
  margin: 1rem 0;
}
.info p {
  width: 100%;
}
.info svg {
  margin-top: 4px;
}

/* Payment Review CSS */
.payment-review-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.payment-review-header h1 {
  color: #212121;
  font-weight: 600;
  font-size: 1.3rem;
  margin: 0.8rem 0;
}
.success-icon {
  height: 7rem;
}

.payment-amount {
  font-size: 1.5rem;
  color: #212121;
  font-weight: bold;
  margin: 8px 0;
}

.payment-info {
  font-weight: 500;
  font-size: 0.9rem;
  color: #212121;
  opacity: 0.6;
  text-align: center;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.action-buttons button {
  font-size: 13px;
  border-radius: 5px;
  cursor: pointer;
  color: #170a33 !important;
  font-weight: 500;
}

.payment-review-container h2 {
  color: #212121;
  font-size: 1.2rem;
}

.payment-review-details {
  padding: 2rem;
  background: #fafffe 0% 0% no-repeat padding-box;
  border: 1px solid #cef5ec;
  border-radius: 10px;
  display: flex;
  gap: 8rem;
  width: 100%;
}
.payment-review-details .details-grid {
  width: 42%;
}
.payment-review-details .details-grid div {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}

.payment-review-details .details-grid div p:first-child {
  color: #212121;
  opacity: 0.4;
  font-weight: 600;
  font-size: 0.95rem;
}
.payment-review-details .details-grid div p:last-child {
  color: #212121;
  font-weight: 500;
  font-size: 1.05rem;
  text-align: right;
}

.fil-selected-btn {
  background: #c3f4e883 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 0.7rem;
  display: flex;
  align-items: center;
  gap: 10px;
}
.fil-selected-btn span {
  font-size: 0.8rem !important;
  display: inline-block;
  text-transform: capitalize !important;
  max-width: 9rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.fil-selected-btn img {
  width: 10px !important;
  cursor: pointer;
}

.acc-sec .value {
  max-width: 11rem;
  min-width: 11rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block !important;
  margin-bottom: 0;
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .fil-selected-btn span {
    max-width: 6rem;
  }
}
