.radio-header-text {
  color: #ff0000;
  font-size: 1rem;
  font-weight: normal;
  margin-bottom: 1rem;
}
.radio-group label span {
  color: #333333 !important;
  font-size: 1rem;
  font-weight: 500;
}

.note .note-text {
  color: #969696;
  font-size: 0.9rem;
  font-weight: normal;
}
.note .note-text span {
  color: #363636;
  font-size: 0.9rem;
  font-weight: 500;
}
.note .note-text .back-link {
  color: #3f81f4;
  font-size: 0.8rem;
  display: block;
  padding: 0px;
}

/* Bank Balance css start  */
.balance-section {
  background: #e4e9f2;
  border: 1px solid #eaeaea;
  border-radius: 7px;
  padding: 1rem 1.2rem;
}
.balance-section .balance-icon {
  width: 2.5rem;
  height: 2.5rem;
}
.balance-section .text-span {
  color: #05152c;
  opacity: 0.5;
  font-size: 0.9rem;
  font-weight: normal;
}
.balance-section .amount-span {
  color: #122549;
  font-size: 1.1rem;
  font-weight: 600;
}
.balance-section .vertical-line {
  border-right: 2px solid #d6d6d6;
  height: 2.2rem;
  margin: 0rem 1rem;
}

/* bank show css start  */
.bank-show-section .bank-show-text {
  font-size: 0.9rem;
  font-weight: 500;
  color: #767676;
}
.bank-show-section .bank-name img {
  width: 2rem;
}
.bank-show-section .bank-name span {
  font-size: 0.9rem;
  font-weight: 500;
  color: #4b5660;
  margin-left: 0.5rem;
}

.bank-show-section .bank-name{
  display: flex;
  align-items: center;
  gap: 6px;
}
.bank-show-section .right-side p {
  font-size: 0.95rem;
  font-weight: 500;
  color: #191919;
  margin-right: 0.8rem;
}
.bank-show-section .right-side p span {
  font-size: 0.9rem;
  color: #767676;
  margin-right: 0.2rem;
}
.bank-show-section .right-side button {
  font-size: 0.9rem;
  color: #3f81f4;
  font-weight: 500;
  padding: 5px 15px;
  background: #ffffff;
  border: 1px solid #f4f4f4;
  border-radius: 5px;
  opacity: 0.91;
  height: 41px;
}
.bank-show-section .right-side button:disabled {
  opacity: 0.5 !important;
}
.bank-show-section .right-side button img {
  width: 0.8rem;
}

.select-statement .label {
  color: #363636;
}

.select-statement .ant-picker,
.select-statement .ant-select-selector {
  border: none !important;
  height: 50px !important;
  border-bottom: 1px solid #d9dee0 !important;
}

.select-statement .ant-select-selection-item {
  display: flex;
  align-items: center;
}

.select-statement .ant-tag {
  background-color: #fff;
  padding: 0.6rem 2rem;
  cursor: pointer;
  border-radius: 6px;
  font-size: 0.85rem;
}

.select-statement .ant-tag.active {
  background: #f5f9ff 0% 0% no-repeat padding-box;
  border: 1px solid #3f81f4;
}

.account-details .account-card {
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.account-details .account-card .first-col {
  width: 61% !important;
}

.account-details .account-card .ant-col {
  flex-grow: 1;
}

.change {
  text-decoration: underline;
  color: var(--primary-color) !important;
  cursor: pointer;
  margin-left: 1rem !important;
}

/* .bank-menu .ant-menu-item svg {
  fill: var(--primary-color);
}

.bank-menu .ant-menu-item span {
  color: var(--primary-color);
} */

.bank-menu .react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
}

.bank-menu .react-datepicker {
  border: none;
  margin: 1rem;
}

.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__month-text,
.react-datepicker__current-month {
  font-family: "Figtree", sans-serif;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__day-name {
  display: block;
  margin: 4px !important;
  font-size: 0.95rem;
  font-weight: 600 !important;
}
.react-datepicker__day-name,
.react-datepicker__month-text,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.4rem !important;
  padding: 6px;
}
.react-datepicker__month-text {
  margin: 10px !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  color: var(--primary-color) !important;
  background-color: var(--primary-color)af !important;
  font-weight: 500;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #6ae3c723 !important;
}

h2.react-datepicker__current-month {
  margin-bottom: 0.5rem !important;
  font-size: 1.1rem;
  position: relative;
  bottom: 5px;
}

.bank-menu {
  width: 115% !important;
  margin-top: 10px;
  transition: all 0.1s ease-in;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.bank-menu .ant-dropdown-menu {
  box-shadow: none;
}

.accounts-sec {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 260px;
  overflow: auto;
}

.accounts-sec label {
  border-bottom: #dbdbdb 1px solid;
  padding-bottom: 1rem;
}
