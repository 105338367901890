@media (min-width: 1024px) {
  .mobile-header,
  .mob-overview-card,
  .money-move-mob,
  .table-mob-header,
  .payment-review-details.desk,
  .mob-left-nav {
    display: none;
  }

  .mobile .list-table {
    display: none;
  }
}
@media (min-width: 320px) and (max-width: 925px) {
  .desktop .table-header,
  .desktop .money-move,
  .payment-review-details.mob,
  .desktop .tableComponent {
    display: none;
  }
  .desktop .left-nav,
  .desktop .header-div {
    display: none !important;
  }
  html {
    font-size: 10px;
  }
  .desktop .login-right {
    display: none;
  }

  .desktop .login-left {
    margin-top: 5rem;
  }

  .desktop .dashboard .account-card {
    display: none;
  }

  .account-details .account-card .w45 {
    width: 100%;
  }
  .mobile-header .mob-logo {
    height: 25px;
  }

  .mobile-header .ham {
    height: 15px;
  }

  .mobile-header {
    position: fixed;
    top: 0px;
    display: flex;
    align-items: center;
    gap: 1rem;
    z-index: 100;
    background: #fafafa;
    padding: 15px 0;
    width: 100%;
  }
  .ant-layout-content {
    position: relative;
    top: 3rem;
  }
  .ant-modal {
    width: 100% !important;
    max-width: 100% !important;
    margin-top: 0;
  }
  .mobile .site-layout.main-content {
    margin: 15px;
    width: 100%;
  }

  .desktop .dash-banner {
    display: none;
  }

  .desktop .quick-btns {
    display: none;
  }
  .acc-num {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .mobile-dash-card {
    background: #fff 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    padding: 10px;
  }
  .ant-dropdown-trigger.mob {
    width: 100%;
  }

  .acc-num .ellipsis {
    max-width: 15rem;
  }

  .avl-bal {
    font-size: 1.2rem;
  }

  .bal-sec h3 {
    font-size: 3rem;
  }

  .pp-input > input {
    height: 32px;
  }

  .send-btn,
  .back-btn {
    height: 32px;
    font-size: 1.1rem;
    padding: 7px 10px;
  }

  .send-btn img {
    width: 10px !important;
  }

  .send-payment .item .value.font-big {
    font-size: 2rem !important;
  }

  .acc-sec .value {
    max-width: 17rem;
    min-width: 17rem;
  }

  .nav-label {
    color: #333;
  }

  .nav-list .menu-selected {
    background: #00e6c446 !important;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .nav-list .submenu-item-selected {
    background: #00e6c446 !important;
  }

  .submenu li {
    width: 70%;
    color: #333;
  }
  .sub-menu-children-item .nav-label {
    font-size: 1.2rem;
  }

  .dashboard .ant-row {
    flex-direction: column;
  }

  .dashboard .dashboard-box {
    width: 100% !important;
  }
  .money-summary.active {
    background: #fafafa;
  }

  .send-payment .w40 {
    width: 100%;
    margin-left: 0rem !important;
  }

  .send-payment .ant-steps {
    display: none;
  }

  .payment-review-details.desk {
    display: block;
  }
  .payment-review-details.desk .details-grid {
    width: 100%;
  }
  .payment-review-details.desk ul {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .payment-review-details.desk ul li {
    font-size: 13px;
  }
  .payment-review-details.desk ul li {
    opacity: 0.5;
  }
  .payment-review-details.desk ul li.active {
    opacity: 1;
  }

  .mobile-view .acc-sec {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .account-row.mobile-view .ant-card-body::after {
    display: none;
  }
  .account-row.mobile-view .ant-card-body {
    display: flex;
    justify-content: space-between;
  }
  .mobile-view .date-time {
    font-size: 11px !important;
    color: #777;
  }

  .table-mob-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .table-mob-header {
    margin-top: 2rem;
  }
  .table-mob-top .actions {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .table-mob-top .actions img {
    width: 1.8rem;
  }

  .table-mob-header .title {
    color: #170a33;
    font-weight: 600;
  }

  .bottom-up-filters {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 80vh;
    background: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
  }
  .overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.76);
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    z-index: 800;
  }

  .bottom-up-filters .bank-menu {
    background: transparent;
    box-shadow: none;
    height: 100%;
    padding: 0 !important;
    width: 100% !important;
  }

  .bank-menu li {
    padding-left: 8px !important;
  }
  .bank-menu .ant-radio-wrapper {
    min-width: 95% !important;
  }
  .bottom-up-filters h1 {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid #fafafa;
  }

  .bank-menu .fil-btns {
    width: 100%;
    position: absolute;
    bottom: 2rem;
    left: 25%;
    flex-direction: column;
  }

  .mob-col{
    flex-direction: column;
    gap: 1rem;
  }
  .mob-col .ant-col-10{
    flex: 0 0 95%;
    max-width: 95%
  }
  .bank-menu .fil-btns button {
    width: 50%;
    justify-content: center !important;
  }
}

@import url(./styles/leftNav.css);
@import url(./styles/overview.css);
